.loadingDiv {
  position: relative;
  margin: auto;
  top: 35vh;
  width: fit-content;
  font-size: 20px;
  text-align: center;
}

.loader {
  position: relative;
  margin: auto;
  height: 80px;
  width: 80px;
  border: 10px solid black;
  border-radius: 50%;  
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  animation: spin 0.8s infinite;
  margin-bottom: 30px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}

.errorComponent {
  position: relative;
  margin: auto;
  width: fit-content;
  top: 30vh;
  max-width: 90%;
}

.errorHeader {
  font-size: 95px;
  font-weight: 800;
}

.errorContent {
  font-size: 20px;
}

.paidComponent {
  position: relative;
  margin: auto;
  width: fit-content;
  top: 30vh;
  max-width: 90%;
  text-align: center;
}

.paidComponent .checkMark {
  color: white;
  font-size: 3rem;
  height: 6rem;
  width: 6rem;
  font-weight: bold;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: rgb(69, 216, 69);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  animation: fadein 1s 1 forwards;
}

.paidComponent {
  position: relative;
  margin: auto;
  width: fit-content;
  top: 30vh;
  max-width: 90%;
}

.paidHeader {
  font-size: 2.5em;
  font-weight: 800;
}

@keyframes fadein {
  from {
    opacity: 0;
  } 
  to {
    opacity: 1;
  }
  
}

a {
  color: black;
}

.collectionComponent {
  position: relative;
  margin: auto;
  width: fit-content;
  top: 30vh;
  max-width: min(90%, 40em);
}

.collectionComponent .header {
  font-size: 40px;
  font-weight: 800;
}

.collectionComponent .content {
  font-size: 20px;
}